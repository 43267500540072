<template>
    <v-fade-transition>
        <div>
            <v-row no-gutters>
                <v-col class="d-flex align-center col-12">
                    <div>
                        <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.audit') }}</span>
                        <v-autocomplete
                            :items="filterItems.audit"
                            :label="$t('message.currentFscYearAudit')"
                            class="ml-0 filter-width-150"
                            dense
                            hide-details="auto"
                            item-value="Audit.id"
                            item-text="Audit.title"
                            solo
                            v-model="filterValues.audit"
                            @change="loadReport()"
                        >
                            <template v-slot:item="{ item }">
                                <div class="text-no-wrap">{{ getYear(item.Audit.start_date) + ' -> ' + getYear(item.Audit.end_date) }}</div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="text-no-wrap">{{ getYear(item.Audit.start_date) + ' -> ' + getYear(item.Audit.end_date) }}</div>
                            </template>
                        </v-autocomplete>
                    </div>
                    <div class="ml-3">
                        <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.search') }}</span>
                        <v-text-field
                            :value="searchTerm"
                            append-icon="search"
                            clearable
                            dense
                            hide-details="auto"
                            solo
                            @change="searchTerm = $event"
                            @click:clear="searchTerm = null"
                        ></v-text-field>
                    </div>
                    <div class="ml-3">
                        <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.entity') }}</span>
                        <v-select
                            :clearable="filterSettings.entity.clearable"
                            :items="filterItems.entity"
                            :label="$t('message.all')"
                            class="ml-0 filter-width-250"
                            dense
                            hide-details="auto"
                            item-value="Office.id"
                            item-text="Office.title"
                            solo
                            v-model="filterValues.entity"
                            @change="loadReport()"
                        ></v-select>
                    </div>
                    <div class="ml-3">
                        <span class="font-xs pa-0 ma-0 font-weight-bold">{{ $t('message.salesType') }}</span>
                        <v-select
                            :items="filterItems.contractType"
                            :label="$t('message.all')"
                            class="ml-0 filter-width-250"
                            clearable
                            dense
                            hide-details="auto"
                            item-value="Salestype.id"
                            item-text="Salestype.title"
                            solo
                            v-model="filterValues.contractType"
                            @change="loadReport()"
                        ></v-select>
                    </div>
                    <v-spacer></v-spacer>
                    <ExportTableFscPefc
                        :export-name="'item'"
                        :export-data="items"
                        :export-fields="headers"
                        :export-certification="'fsc'"
                        :export-conditions="conditions"
                    ></ExportTableFscPefc>
                </v-col>
            </v-row>
            <v-overlay
                :value="loading.items"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-data-table
                :items="items"
                :items-per-page="-1"
                :headers="headers"
                :height="tableHeight"
                :search="searchTerm"
                :sort-by="['InputOutput.date']"
                :sort-desc="[true]"
                class="mt-3 appic-table-light specification-table"
                dense
                disable-pagination
                fixed-header
                hide-default-footer
                ref="inputOutputTable"
                item-key="InputOutput.uuid"s
            >
                <template v-slot:item.InputOutput.date="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.InputOutput.month_year }}</div>
                </template>
                <template v-slot:item.InputOutput.contract="{item}">
                    <div class="text-no-wrap font-weight-bold">{{ item.InputOutput.contract }}</div>
                </template>
                <template v-slot:item.InputOutput.fsc_certificate="{item}">
                    <div class="text-no-wrap" v-if="item.InputOutput.fsc_certificate != null">{{ item.InputOutput.fsc_certificate }}</div>
                    <div v-else>
                        <template v-if="$can('update','Supplier')">
                            <v-btn text small class="red--text darken-1 missing" @click="updateSupplier(item.InputOutput.supplier_id)">{{ $t('message.missingFmCoc') }}</v-btn>
                        </template>
                        <template v-else>
                            <span class="red--text darken-1">{{ $t('message.missingFmCoc') }}</span>
                        </template>
                    </div>
                </template>
                <template v-slot:item.InputOutput.fsc_product_category="{item}">
                    <div v-if="item.InputOutput.fsc_product_category != null">{{ item.InputOutput.fsc_product_category }}</div>
                    <div v-else>
                        <template v-if="$can('access','SpecificationsData')">
                            <v-btn text small class="red--text darken-1 missing" @click="updateProductCategory(item.InputOutput.appic_product_id)">{{ $t('message.missingProductCategory') }}</v-btn>
                        </template>
                        <template v-else>
                            <span class="red--text darken-1">{{ $t('message.missingProductCategory') }}</span>
                        </template>
                    </div>
                </template>
                <template v-slot:item.InputOutput.input_claim="{item}">
                    <div class="text-no-wrap" v-if="item.InputOutput.input_claim != null">{{ item.InputOutput.input_claim }}</div>
                    <div v-else class="red--text darken-1">{{ $t('message.missingClaim') }}</div>
                </template>
                <template v-slot:item.InputOutput.buyer="{item}">
                    <div v-if="item.InputOutput.buyer != null">{{ item.InputOutput.buyer }}</div>
                    <div v-else>
                        <template v-if="$can('update','Buyer')">
                            <v-btn text small class="red--text darken-1 missing" @click="updateBuyer()">{{ $t('message.missingBuyer') }}</v-btn>
                        </template>
                        <template v-else>
                            <span class="red--text darken-1">{{ $t('message.missingBuyer') }}</span>
                        </template>
                    </div>
                </template>
                <template v-slot:item.InputOutput.output_claim="{item}">
                    <div class="text-no-wrap" v-if="item.InputOutput.output_claim != null">{{ item.InputOutput.output_claim }}</div>
                    <div v-else class="red--text darken-1">{{ $t('message.missingClaim') }}</div>
                </template>
                <template v-slot:item.InputOutput.species="{item}">
                    <div class="text-no-wrap" v-if="item.InputOutput.species != null">{{ item.InputOutput.species }}</div>
                    <div v-else class="red--text darken-1">{{ $t('message.missingSpecies') }}</div>
                </template>
                <template v-slot:item.InputOutput.species_scientific="{item}">
                    <div class="text-no-wrap" v-if="item.InputOutput.species_scientific != null">{{ item.InputOutput.species_scientific }}</div>
                    <div v-else>
                        <template v-if="$can('access','SpecificationsData')">
                            <v-btn text small class="red--text darken-1 missing" @click="updateSpecies(item.InputOutput.species_id)">{{ $t('message.missingScientificName') }}</v-btn>
                        </template>
                        <template v-else>
                            <span class="red--text darken-1">{{ $t('message.missingScientificName') }}</span>
                        </template>
                    </div>
                </template>
                <template v-slot:item.InputOutput.invoice="{item}">
                    <div class="text-no-wrap" v-if="item.InputOutput.invoice != null">{{ item.InputOutput.invoice }}</div>
                    <div class="red--text darken-1" v-if="item.InputOutput.invoice == null && item.InputOutput.salestype != 3" >{{ $t('message.invoiceNotAvailable') }}</div>
                    <div class="red--text darken-1" v-if="item.InputOutput.salestype == 3" >{{ $t('message.na') }}</div>
                </template>
                <template v-slot:item.InputOutput.input_volume="{item}">
                    <div class="text-right text-no-wrap">{{ formatThisNumber(item.InputOutput.input_volume, '0,0.000') + (item.InputOutput.uom ? ' ' + item.InputOutput.uom : '') }}</div>
                </template>
                <template v-slot:item.InputOutput.output_volume="{item}">
                    <div class="text-right text-no-wrap">{{ formatThisNumber(item.InputOutput.output_volume, '0,0.000') + (item.InputOutput.uom ? ' ' + item.InputOutput.uom : '') }}</div>
                </template>
                <template v-slot:item.InputOutput.input_amount="{item}">
                    <div class="text-right text-no-wrap">{{ formatThisNumber(item.InputOutput.input_amount, '0,0.00') + (item.InputOutput.contract_currency ? ' ' + item.InputOutput.contract_currency : '') }}</div>
                </template>
                <template v-slot:item.InputOutput.output_amount="{item}">
                    <div class="text-right text-no-wrap">{{ formatThisNumber(item.InputOutput.output_amount, '0,0.00') + (item.InputOutput.invoice_currency ? ' ' + item.InputOutput.invoice_currency : '') }}</div>
                </template>
            </v-data-table>
        </div>
    </v-fade-transition>
</template>

<script>
import {api} from "Api";
import {formatDate, formatDateShort, log, numberFormat, getYear} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";
const ExportTableFscPefc = () => import("Components/Appic/ExportTableFscPefc");

export default {
    name: "FscAnnualInOutList",
    components: {ExportTableFscPefc},
    data() {
        return {
            conditions: [],
            filterItems: {
                audit: [],
                entity: [],
                contractType: []
            },
            filterSettings: {
                audit: {},
                entity: {
                    clearable: true
                },
                contractType: {}
            },
            filterValues: {
                audit: null,
                entity: null,
                contractType: null
            },
            loading: {
                audits: false,
                clear: false,
                items: false
            },
            items: [],
            searchTerm: null,
            tableHeight: '500'
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs',
            fscPefcShowNoInvoices: 'fscPefcShowNoInvoices'
        }),
        ...mapFields('user',{
            User__country_id: 'current.User.country_id'
        }),
        ...mapGetters([
            'offices',
            'salesTypes'
        ]),
        headers() {
            return [
                {
                    id: 1,
                    text: this.$t('message.month'),
                    value: 'InputOutput.date',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 2,
                    text: this.$t('message.poContractInvoice'),
                    value: 'InputOutput.contract',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.supplier'),
                    value: 'InputOutput.supplier',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 4,
                    text: this.$t('message.fmCoc'),
                    value: 'InputOutput.fsc_certificate',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 5,
                    text: this.$t('message.productCategory'),
                    value: 'InputOutput.fsc_product_category',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 6,
                    text: this.$t('message.inputClaim'),
                    value: 'InputOutput.input_claim',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 7,
                    text: this.$t('message.buyer'),
                    value: 'InputOutput.buyer',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 8,
                    text: this.$t('message.outputClaim'),
                    value: 'InputOutput.output_claim',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 9,
                    text: this.$t('message.speciesTradeName'),
                    value: 'InputOutput.species',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 10,
                    text: this.$t('message.speciesScientificName'),
                    value: 'InputOutput.species_scientific',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 11,
                    text: this.$t('message.invoice'),
                    value: 'InputOutput.invoice',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 12,
                    text: this.$t('message.inputVolume'),
                    value: 'InputOutput.input_volume',
                    class: 'light-green lighten-3 pa-1 force-text-right',
                    sortable: false
                },
                {
                    id: 13,
                    text: this.$t('message.outputVolume'),
                    value: 'InputOutput.output_volume',
                    class: 'light-green lighten-3 pa-1 force-text-right',
                    sortable: false
                },
                {
                    id: 14,
                    text: this.$t('message.inputAmount'),
                    value: 'InputOutput.input_amount',
                    class: 'light-green lighten-3 pa-1 force-text-right',
                    sortable: false
                },
                {
                    id: 15,
                    text: this.$t('message.outputAmount'),
                    value: 'InputOutput.output_amount',
                    class: 'light-green lighten-3 pa-1 force-text-right',
                    sortable: false
                },
                {
                    id: 16,
                    text: this.$t('message.entity'),
                    value: 'InputOutput.entity',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                },
                {
                    id: 17,
                    text: this.$t('message.site'),
                    value: 'InputOutput.site',
                    class: 'light-green lighten-3 pa-1',
                    sortable: true
                }
            ]
        }
    },
    methods: {
        clearFilters() {
            this.filterValues.audit = null
            this.loadReport()
        },
        formatDate,
        formatDateShort,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getYear,
        handleResize() {
            this.tableHeight = window.innerHeight - (280);
        },
        loadAudits() {
            return new Promise((resolve, reject) => {
                this.loading.audits = true
                api
                    .get('/fsc-pefc/audits',{
                        params: {
                            conditions: [{field: 'Audit.type', value: 'fsc'}]
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.filterItems.audit = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.audits = false
                        this.filterValues.audit = this.filterItems.audit[0]['Audit']['id']
                        this.loadReport()
                    })
                    .catch((error) => {
                        this.$toast.error( error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.audits = false
                    });
            })
        },
        loadReport() {
            return new Promise((resolve, reject) => {
                this.conditions = [];
                if(this.filterValues.audit != null){
                    this.conditions.push({field: 'Audit.id', value: this.filterValues.audit})
                }
                if(this.filterValues.entity != null){
                    this.conditions.push({field: 'Audit.site', value: this.filterValues.entity})
                }
                if(this.filterValues.contractType != null){
                    this.conditions.push({field: 'Audit.contract_type', value: this.filterValues.contractType})
                }
                if(this.fscPefcShowNoInvoices !== null){
                    this.conditions.push({field: 'show_no_invoices', value: this.fscPefcShowNoInvoices})
                }
                this.loading.items = true
                api
                    .get('/fsc-pefc/items', {
                        params: {
                            conditions: this.conditions,
                            type: 'fsc'
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            this.items = response.data.data
                            resolve('done')
                        } else {
                            reject(response.data.status)
                        }
                        this.loading.items = false
                    })
                    .catch((error) => {
                        this.$toast.error( error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        reject('error')
                        this.loading.items = false
                    });
            })
        },
        updateBuyer () {
            this.$toast.info(this.$t('message.updateContractDirectly'),
                {
                    classes: ['icon-float-left', 'font-2x'],
                    icon: 'info',
                    y: 'top',
                    timeout: 5000,
                    showClose: true
                }
            )
        },
        updateContract (val) {
            let tab = window.open('/v1/contracts/update/' + val,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateProductCategory(val) {
            let tab = window.open('/v1/specifications-data?panel=products&id=' + val, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateSpecies(val) {
            let tab = window.open('/v1/specifications-data?panel=species&id=' + val, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        updateSupplier(val) {
            let tab = window.open('/v1/suppliers/update/' + val + '?panel=operations', '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize()
        this.loadAudits()
        this.loadReport()

        if(this.$can('limitedAccess','FscPefcReport')){
            this.filterItems.entity = this.offices.filter(office => this.User__country_id == office.Office.country_id)
            this.filterValues.entity = this.filterItems.entity[0]['Office']['id']
            this.filterSettings.entity.clearable = false
        } else {
            this.filterItems.entity = this.offices.filter(office => [1, 20, 21, 22].includes(office.Office.id))
        }

        this.filterItems.contractType = this.salesTypes.filter(type => [1,3,5,99].includes(type.Salestype.id))
        this.filterItems.contractType.sort( (a, b) => {
            if (a.Salestype.id == 1 || b.Salestype.id == 1) {
                return 1
            } else if(a.Salestype.id == 3 && b.Salestype.id == 5) {
                return 1
            } else if(a.Salestype.id == 5 && b.Salestype.id == 3) {
                return -1
            }
        })
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-250 {
    width: 250px !important;
    max-width: 250px !important;
}
.font-xs {
    font-size: 0.80rem;
}
.v-data-table::v-deep .text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table::v-deep td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    vertical-align: middle !important;
}
.v-data-table::v-deep th {
    white-space: nowrap;
}
.v-data-table::v-deep th.force-text-right {
    text-align: right !important;
}
.missing {
    padding: 0 0px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
    padding-left: 0px !important;
}
</style>